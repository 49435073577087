<template>
  <div class="p">
    <div class="middle">
      <el-table
          ref="table"
          v-loading="searchLoading"
          :data="pageProps.records"
          element-loading-text="正在查询"
          fit
          stripe
          highlight-current-row
        >
        
        <el-table-column prop="project_name" label="项目名称" >
        </el-table-column>
      
        <el-table-column prop="title" label="职位名称" >
        </el-table-column>

        <el-table-column prop="created_at" label="职位发布时间" >
        </el-table-column>
        
        <el-table-column prop="nickname" label="主管" >
        </el-table-column>
        

        <el-table-column prop="status_str" label="审核状态" >
        </el-table-column>

        <el-table-column label="操作" fixed="right" align="center" class-name="small-padding "  width="200px">
            <template slot-scope="{row}">
            
              <el-button  type="primary" size="mini" @click="detail(row.job_id)">
                详情
              </el-button>

              <el-button  type="primary" size="mini" @click="audit(row.job_id)" v-if="row.status==0">
                审核
              </el-button>

            </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="text-align:center;padding:20px">
      <!--分页-->
      <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageProps.total"
          :page-size="pageProps.pageSize"
          :page-sizes="[5, 10, 50, 100]"
          :current-page="pageProps.pageNum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
    </div>     
    <job-dialog ref="jobDialog" @refresh="fetchData"></job-dialog>
 
  </div>
</template>

<script>
import { jobList } from "@/api/project/index";
import jobDialog from './dialog/job-dialog.vue';


export default {
  components: {
    jobDialog
  },
  data() {
    return {
      searchLoading: false,
        pageProps: {
          records:[],
          total: 0,
          pageSize: 10,
          pageNum: 1
        },

        searchFormData:{
          job_id:"",	
          link:"1",
          name:"",
          page:0,
          limit:0
        },
    };
  },
  mounted() {
      this.fetchData();
    },
  methods: {

    fetchData(){
        var that = this;
        that.searchFormData.page=that.pageProps.pageNum;
        that.searchFormData.limit=that.pageProps.pageSize;

        jobList(that.searchFormData).then((response) => {
          console.log(response);
          if(response.code==200){
            that.pageProps.records = response.data.data;
            that.pageProps.total = response.data.total;
          }else{
            that.$message.error(response.msg?response.msg:"请求错误");
          }
         
        });
      },

       // 当前页变更
      handleCurrentChange(num) {
        this.pageProps.pageNum = num
        this.fetchData()
      },


      // 每页显示页面数变更
      handleSizeChange(size) {
        this.pageProps.pageSize = size
        this.fetchData()
      },
      back(){
        this.$router.go(-1);
      },

      detail(job_id){

        this.$refs.jobDialog.init(job_id);

      },
      audit(job_id){

        this.$refs.jobDialog.init(job_id);

      },

      formatDateTime(value) { // 时间戳转换日期格式方法
        if (value == null) {
          return ''
        } else {
          const date = new Date(value)
          const y = date.getFullYear() // 年
          let MM = date.getMonth() + 1 // 月
          MM = MM < 10 ? ('0' + MM) : MM
          let d = date.getDate() // 日
          d = d < 10 ? ('0' + d) : d
          let h = date.getHours() // 时
          h = h < 10 ? ('0' + h) : h
          let m = date.getMinutes()// 分
          m = m < 10 ? ('0' + m) : m
          let s = date.getSeconds()// 秒
          s = s < 10 ? ('0' + s) : s
          return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
          }
      }
  },
};
</script>

<style>

</style>
