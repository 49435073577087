import request from '@/api/request'





//PC企业端-项目列表
export function projectList(data) {
    return request({
        url: '/company/company/project/list',
        method: 'GET',
        params:data
    })
}

//PC企业端-全部职位列表
export function jobList(data) {
    return request({
        url: '/company/company/job/list',
        method: 'GET',
        params:data
    })
}

export function projectJobList(data) {
    return request({
        url: '/company/company/project/job/list',
        method: 'GET',
        params:data
    })
}

//PC企业端-项目下的众包列表
export function projectCrowdList(data) {
    return request({
        url: '/company/company/project/crowd/list',
        method: 'GET',
        params:data
    })
}
///company/company/crowd/info
export function crowdInfo(data) {
    return request({
        url: '/company/company/crowd/info',
        method: 'GET',
        params:data
    })
}

export function crowdUpdate(data) {
    return request({
        url: '/company/company/crowd/examine',
        method: 'POST',
        data
    })
}

//PC企业端-众包列表
export function crowdList(data) {
    return request({
        url: '/company/company/crowd/list',
        method: 'GET',
        params:data
    })
}

//主管列表
export function userList(data) {
    return request({
        url: '/company/company/user/list',
        method: 'GET',
        params:data
    })
}

//PC企业端-项目详情
export function projectInfo(data) {
    return request({
        url: '/company/company/project/info',
        method: 'GET',
        params:data
    })
}


export function projectExamine(data) {
    return request({
        url: '/company/company/project/examine',
        method: 'POST',
        data
    })
}

//PC企业端-职位详情
export function jobInfo(data) {
    return request({
        url: '/company/company/job/info',
        method: 'GET',
        params:data
    })
}

//PC企业端-职位审核
export function jobExamine(data) {
    return request({
        url: '/company/company/job/examine',
        method: 'POST',
        data
    })
}

// /PC企业端-主管详情
export function userInfo(data) {
    return request({
        url: '/company/company/user/info',
        method: 'GET',
        params:data
    })
}
//PC企业端-主管审核
export function userExamine(data) {
    return request({
        url: '/company/company/user/examine',
        method: 'POST',
        data
    })
}

export function auditSettings(data) {
    return request({
        url: '/company/auditSettings',
        method: 'POST',
        data
    })
}

export function getAuditSettings(data) {
    return request({
        url: '/company/getAuditSettings',
        method: 'GET',
        params:data
    })
}









