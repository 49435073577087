<template>
  <el-dialog
    :visible.sync="visible"
    append-to-body
    :show-close="true"
    :center="true"
    :before-close="handleClose"
    title="职位详情"
    width="800px"
  >
    <div>
      <el-form ref="form" :model="formData"  :rules="rules" label-width="100px" inline="true">
        <el-form-item label="项目名称" style="color:#4C71DB">  
           {{info.title}}
        </el-form-item>

        <el-form-item label="是否报名">  
         {{info.enroll_status==1?"已报名":"未报名"}}
        </el-form-item>

        <el-form-item label="是否收藏">  
         {{info.collect_status==1?"已收藏":"未收藏"}}
        </el-form-item>

        <el-form-item label="	工作类型">  
         {{info.type_id==1?"全职":"兼职"}}
        </el-form-item>

        
        <el-form-item label="薪资">
          {{info.wages}}
        </el-form-item>


        <el-form-item label="薪资单位">
          {{info.unit}}
        </el-form-item>

        <el-form-item label="工作时间">
          {{info.work_time}}
        </el-form-item>

        <el-form-item label="性别">
          {{info.sex==1?"男":info.sex==2?"女":"不限"}}
        </el-form-item>
        

        <el-form-item label="学历限制" >
          {{info.education_str}}
        </el-form-item>

        <el-form-item label="截止日期" >
          {{info.count_down}}
        </el-form-item>


        <el-form-item label="福利标签" >
          {{info.benefits}}
        </el-form-item>

        <el-form-item label="距离" >
          {{info.km}}
        </el-form-item>
        <el-form-item label="发布时间" >
          {{info.created_at}}
        </el-form-item>

        <el-form-item label="发布人" >
          {{info.nickname}}
        </el-form-item>

        <el-form-item label="企业名称" >
          {{info.company_name}}
        </el-form-item>


        <el-form-item label="职位数量" >
          {{info.job_count}}
        </el-form-item>
        <el-form-item label="众包数量" >
          {{info.crowd_count}}
        </el-form-item>

        <el-form-item label="项目名称" >
          {{info.project_name}}
        </el-form-item>
        <el-form-item label="职位开始时间" >
          {{info.job_start_at	}}
        </el-form-item>
        <el-form-item label="职位结束时间" >
          {{info.job_end_at}}
        </el-form-item>
        <el-form-item label="活动起始时间" >
          {{info.job_at}}
        </el-form-item>
        <el-form-item label="报名截止时间" >
          {{info.registration_end_time	}}
        </el-form-item>

        <el-form-item label="年龄要求" >
          {{info.age	}}
        </el-form-item>

        <el-form-item label="结算方式" >
          {{info.method_str	}}
        </el-form-item>

        <el-form-item label="兼职性质" >
          {{info.nature	}}
        </el-form-item>
        	
        <el-form-item label="任职描述" >
          {{info.description	}}
        </el-form-item>
        <el-form-item label="招聘人数" >
          {{info.number	}}
        </el-form-item>
        	


        	

        <el-form-item label="审核" v-if="info.status==0" required>
          <el-radio-group v-model="formData.status" size="small">
            <el-radio label="1" border>通过</el-radio>
            <el-radio label="5" border>拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="拒绝理由"  v-if="info.status==0" required >
          <el-input 
            type="textarea" 
            v-model="formData.reason"  
            :rows="4"
            placeholder="输入拒绝原因"></el-input>
        </el-form-item>

      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit"  v-if="info.status==0" >确 定</el-button>
      <el-button @click="handleClose">取 消</el-button>
    </span>


  </el-dialog>
</template>

<script>
import { jobInfo,jobExamine } from "@/api/project/index";


export default {

  data() {
    return {
      visible: false,
      formData:{
        job_id:"",
        status:'1',
        reason:'',

      },
      info: {
        enroll_status	:"",//	是否报名1已报名，2未报名
        collect_status	:"",//	是否收藏1已收藏，2未收藏
        logo	:"",//	职位头像
        type_id	:"",//	工作类型，1兼职，2全职
        wages	:"",//	薪资
        unit	:"",//	薪资单位
        work_time	:"",//	工作时间
        sex	:"",//	性别1男2女0不限
        education_str	:"",//	学历限制
        count_down	:"",//	截止日期
        benefits	:"",//	福利标签
        km	:"",//	距离
        created_at	:"",//	发布时间
        nickname	:"",//	发布人
        avater	:"",//	发布人头像
        company_name	:"",//	企业名称
        log_img	:"",//	企业头像
        job_count	:"",//	职位数量
        crowd_count	:"",//	众包数量
        project_name	:"",//	项目名称
        job_start_at	:"",//	职位开始时间
        job_end_at	:"",//	职位结束时间
        job_at	:"",//	活动起始时间
        registration_end_time	:"",//	报名截止时间
        age	:"",//	年龄要求
        method_str	:"",//	结算方式
        nature	:"",//	兼职性质
        photo	:"",//	工作环境
        nature	:"",//	兼职性质
        description	:"",//	任职描述
        number	:"",//	招聘人数
      },
       rules: {
        

       }
    };
  },

 
  mounted() {

  },

  
  methods: {
    handleClose() {
      this.$refs["form"].resetFields();
      this.visible = false;

    },


    init(job_id) {
      this.visible = true;
      this.formData.job_id=job_id;
      this.initInfo();

    },

    initInfo(){
      var that = this;
      jobInfo({"job_id":that.formData.job_id	}).then((response) => {
        if(response.code==200){
          that.info = response.data;
        }else{
          that.$message.warning(response.msg?response.msg:"获取信息失败");
        }
      });
    },

    
    submit(){
      var that=this;
      if(this.formData.reason==""&&that.formData.status==5){
        that.$message.warning("请输入拒绝理由");
        return;
      }
      
      this.$refs["form"].validate((valid) => {
          if (valid) {
            jobExamine(that.formData).then((response) => {
              console.log(response);
              if(response.code==200){
                this.$message({
                   message: "提交成功",
                   type: 'success'
                });
                that.visible = false;
                that.$emit("refresh")
              
              }else{
                this.$message({
                  message: response.msg?response.msg:"提交失败",
                });
              }
            });
          } 
        });
    },

  },

 
};
</script>

<style lang="scss" scoped>
/deep/ .el-form-item .el-form-item__label {
  color: #9b9b9c !important;
}

/deep/ .el-form-item {
    width: 40% !important;
}


/deep/ .myWindow {
    border-radius: 15px;



.el-button--primary {
  color: #FFF;
  background-color: #717ebb;
  border-color: #717ebb;
}


/*
.footer{
  display: flex;
  justify-content: center;
}*/


  }
</style>
